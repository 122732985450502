import Vuex from "vuex";
import Vue from "vue";
import XLSX from "xlsx"; // reads excel sheets
import axios from "axios";
//const BASE_URL = process.env.VUE_APP_BASE_URL;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    // state holds your application wide data
    currentQuestionId: 1,
    previousQuestionId: [],
    QuestionList: [],
    AnswerList: [],
    Resources: [],
    language: "",
    docID: "", // reference to Analytics object being saved to db
    UserReport: {},
    county: "",
  };
};

export default new Vuex.Store({
  state: getDefaultState(),
  // actions are used to commit mutations
  actions: {
    resetState({ commit }) {
      commit("clearState");
    },
    fetchSpreadsheet({ commit }) {
      // axios.get(`http://localhost:3000/api/fetchspreadsheet`).then(response => {
      axios.get(`/fetchspreadsheet`).then((response) => {
        commit(
          "loadQuestions",
          XLSX.utils.sheet_to_json(response.data.workbook.Sheets.questions)
        );

        commit(
          "loadAnswers",
          XLSX.utils.sheet_to_json(response.data.workbook.Sheets.answers)
        );

        commit(
          "loadResources",
          XLSX.utils.sheet_to_json(response.data.workbook.Sheets.resources)
        );
      });
    },

    fetchSpanishSpreadsheet({ commit }) {
      // axios.get(`http://localhost:3000/api/fetchspanishspreadsheet`).then(response => {
      axios.get(`/fetchspanishspreadsheet`).then((response) => {
        commit(
          "loadQuestions",
          XLSX.utils.sheet_to_json(response.data.workbook.Sheets.questions)
        );

        commit(
          "loadAnswers",
          XLSX.utils.sheet_to_json(response.data.workbook.Sheets.answers)
        );

        commit(
          "loadResources",
          XLSX.utils.sheet_to_json(response.data.workbook.Sheets.resources)
        );
      });
    },

    storeLanguage({ commit }, language) {
      commit("storeLanguage", language);
    },

    popLastQuestionID({ commit }) {
      commit("popLastQuestionID");
    },
    // Creates a new empty entry inside database
    // then stores a reference to that document
    newAnalytics({ commit }) {
      // axios.post(`http://localhost:3000/api/Analytics/create`).then(response => {

      axios.post(`/Analytics/create`).then((response) => {
        commit("storeDocRef", response.data.Analytics._id);
      });
    },

    newSpanishAnalytics({ commit }) {
      // axios.post(`http://localhost:3000/api/AnalyticsSpanish/create`).then(response => {

      axios.post(`/AnalyticsSpanish/create`).then((response) => {
        commit("storeDocRef", response.data.AnalyticsSpanish._id);
      });
    },

    // Sends post requests to update the database entry as users answer questions
    saveToDatabase({ commit }, data) {
      commit("updateAnalytics", data);
    },

    updatePreviousQuestionID({ commit }, id) {
      commit("updatePreviousQuestionID", id);
    },

    // Send triage to next question
    updateQuestion({ commit }, questionID) {
      commit("updateQuestion", questionID);
    },

    // Store More Info Resource in User Report
    AboutSituation({ commit }, data) {
      commit("appendAboutSituation", data);
    },

    // Store More Info Resource in User Report
    MoreInfo({ commit }, data) {
      commit("appendMoreInfo", data);
    },

    // Store Find Help Resource in User Report
    FindHelp({ commit }, data) {
      commit("appendFindHelp", data);
    },

    // Store Next Steps in User Report
    NextStep1({ commit }, data) {
      commit("appendNextStep1", data);
    },
    NextStep2({ commit }, data) {
      commit("appendNextStep2", data);
    },

    NextStep3({ commit }, data) {
      commit("appendNextStep3", data);
    },

    countySelected({ commit }, data) {
      commit("saveSelectedCounty", data);
    },

    removeSavedData({ commit }, questionID) {
      var previousQuestion = this.state.QuestionList.filter((question) => {
        return question.id == questionID;
      })[0];

      commit("updateAnalytics", {
        questionTitle: previousQuestion.question,
        choice: "",
      });
    },
  },
  mutations: {
    clearState(state) {
      Object.assign(state, getDefaultState());
    },
    // mutations are what actually alter your state
    loadQuestions(state, fetchedQuestions) {
      state.QuestionList = fetchedQuestions;
    },

    loadAnswers(state, fetchAnswers) {
      state.AnswerList = fetchAnswers;
    },

    loadResources(state, fetchedResources) {
      state.Resources = fetchedResources;
    },

    storeLanguage(state, language) {
      state.language = language;
    },

    storeDocRef(state, docID) {
      state.docID = docID;
    },

    saveSelectedCounty(state, data) {
      state.county = data.county;
    },

    updatePreviousQuestionID(state, id) {
      state.previousQuestionId.push(id);
    },
    popLastQuestionID(state) {
      state.previousQuestionId.pop();
    },

    updateQuestion(state, QuestionID) {
      state.currentQuestionId = QuestionID;
    },
    updateAnalytics(state, data) {
      if (state.language == "EN") {
        axios
          // .post(`http://localhost:3000/api/Analytics/update/${state.docID}`,{

          .post(`/Analytics/update/${state.docID}`, {
            questionTitle: data.questionTitle,
            choice: data.choice,
          })
          .then((response) => {
            return response.data;
          })
          .catch((err) => Promise.reject(err.message));
      } else if (state.language == "ES") {
        axios
          // .post(`http://localhost:3000/api/Analytics/update/${state.docID}`,{

          .post(`/AnalyticsSpanish/update/${state.docID}`, {
            questionTitle: data.questionTitle,
            choice: data.choice,
          })
          .then((response) => {
            return response.data;
          })
          .catch((err) => Promise.reject(err.message));
      }
    },
    appendAboutSituation(state, data) {
      if (typeof state.UserReport[data.category] == "undefined") {
        Vue.set(state.UserReport, [data.category], {});
      }

      if (
        typeof state.UserReport[data.category].AboutSituation == "undefined"
      ) {
        Vue.set(state.UserReport[data.category], "AboutSituation", []);
      }

      if (!state.UserReport[data.category].AboutSituation.includes(data.data)) {
        state.UserReport[data.category].AboutSituation.pop();
        state.UserReport[data.category].AboutSituation.push(data.data);
      }
    },

    appendMoreInfo(state, data) {
      if (typeof state.UserReport[data.category] == "undefined") {
        Vue.set(state.UserReport, [data.category], {});
      }

      if (typeof state.UserReport[data.category].MoreInfo == "undefined") {
        Vue.set(state.UserReport[data.category], "MoreInfo", []);
      }
      if (!state.UserReport[data.category].MoreInfo.includes(data.data)) {
        state.UserReport[data.category].MoreInfo.push(data.data);
      }
    },

    appendFindHelp(state, data) {
      if (typeof state.UserReport[data.category] == "undefined") {
        Vue.set(state.UserReport, [data.category], {});
      }

      if (typeof state.UserReport[data.category].FindHelp == "undefined") {
        Vue.set(state.UserReport[data.category], "FindHelp", []);
      }

      if (!state.UserReport[data.category].FindHelp.includes(data.data)) {
        state.UserReport[data.category].FindHelp.pop();

        state.UserReport[data.category].FindHelp.push(data.data);
      }
    },

    appendNextStep1(state, data) {
      if (typeof state.UserReport[data.category] == "undefined") {
        Vue.set(state.UserReport, [data.category], {});
      }

      if (typeof state.UserReport[data.category].NextStep1 == "undefined") {
        Vue.set(state.UserReport[data.category], "NextStep1", []);
      }
      if (
        typeof state.UserReport[data.category].NextStep1Title == "undefined"
      ) {
        Vue.set(state.UserReport[data.category], "NextStep1Title", []);
      }

      if (!state.UserReport[data.category].NextStep1.includes(data.data)) {
        state.UserReport[data.category].NextStep1.pop();
        state.UserReport[data.category].NextStep1.push(data.data);
      }
      if (
        !state.UserReport[data.category].NextStep1Title.includes(data.title)
      ) {
        state.UserReport[data.category].NextStep1Title.pop();
        state.UserReport[data.category].NextStep1Title.push(data.title);
      }
    },

    appendNextStep2(state, data) {
      if (typeof state.UserReport[data.category] == "undefined") {
        Vue.set(state.UserReport, [data.category], {});
      }

      if (typeof state.UserReport[data.category].NextStep2 == "undefined") {
        Vue.set(state.UserReport[data.category], "NextStep2", []);
      }
      if (
        typeof state.UserReport[data.category].NextStep2Title == "undefined"
      ) {
        Vue.set(state.UserReport[data.category], "NextStep2Title", []);
      }
      if (!state.UserReport[data.category].NextStep2.includes(data.data)) {
        state.UserReport[data.category].NextStep2.pop();
        state.UserReport[data.category].NextStep2.push(data.data);
      }
      if (
        !state.UserReport[data.category].NextStep2Title.includes(data.title)
      ) {
        state.UserReport[data.category].NextStep2Title.pop();
        state.UserReport[data.category].NextStep2Title.push(data.title);
      }
    },

    appendNextStep3(state, data) {
      if (typeof state.UserReport[data.category] == "undefined") {
        Vue.set(state.UserReport, [data.category], {});
      }

      if (typeof state.UserReport[data.category].NextStep3 == "undefined") {
        Vue.set(state.UserReport[data.category], "NextStep3", []);
      }
      if (
        typeof state.UserReport[data.category].NextStep3Title == "undefined"
      ) {
        Vue.set(state.UserReport[data.category], "NextStep3Title", []);
      }

      if (!state.UserReport[data.category].NextStep3.includes(data.data)) {
        state.UserReport[data.category].NextStep3.pop();
        state.UserReport[data.category].NextStep3.push(data.data);
      }
      if (
        !state.UserReport[data.category].NextStep3Title.includes(data.title)
      ) {
        state.UserReport[data.category].NextStep3Title.pop();
        state.UserReport[data.category].NextStep3Title.push(data.title);
      }
    },
  },
  getters: {
    // getters allow you retrieve state within your components
    allQuestions(state) {
      return state.QuestionList;
    },
    allAnswers(state) {
      return state.AnswerList;
    },
    currentQuestionId(state) {
      return state.currentQuestionId;
    },
    getUserReport(state) {
      return state.UserReport;
    },
    getResources(state) {
      return state.Resources;
    },
    getCounty(state) {
      return state.county;
    },
    getLanguage(state) {
      return state.language;
    },
    getPreviousQuestionId(state) {
      return state.previousQuestionId[state.previousQuestionId.length - 1];
    },
  },
});
