/**
Author: Tyshan Prince (tyshantprince@gmail.com)
Date: July 09, 2019


Description:

Main component for application.

Setup vuex store for application state

Create new document in mongoDB to hold user report

*/


<template>
  <div id="q-app">
    <router-view />
  </div>
</template>

<script>


export default {
  name: "App",
};
</script>

<style></style>
