import Vue from 'vue'

import './styles/quasar.styl'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'


import {
  Quasar, 
  QLayout,
  QHeader,
  QDrawer,
  QPageContainer,
  QPage,
  QToolbar,
  QToolbarTitle,
  QBtn,
  QIcon,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QSelect,
  QStep,
  QStepper,
  QStepperNavigation,
  QTab,
  QTabPanel,
  QTabPanels,
  QTabs,
  QBanner,
  QCard,
  QBtnToggle,
  QCardSection,
  QCardActions,
  QSeparator,
  QExpansionItem,
  QSplitter,
  QDialog,
  QAvatar,
  ClosePopup,
  QFooter,
  QSpinnerIos,
  QScrollArea,
  QPageSticky,
  QInput,
  QForm


  

} from 'quasar'

Vue.use(Quasar, {
  config: {},
  components: {
    QLayout,
    QHeader,
    QDrawer,
    QPageContainer,
    QPage,
    QToolbar,
    QToolbarTitle,
    QBtn,
    QIcon,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QSelect,
    QStep,
    QStepper,
    QStepperNavigation,
    QTab,
    QTabPanel,
    QTabPanels,
    QTabs,
    QBanner,
    QCard,
    QBtnToggle,
    QCardSection,
    QCardActions,
    QExpansionItem,
    QSeparator,
    QSplitter,
    QAvatar,
    QDialog,
    QFooter,
    QSpinnerIos,
    QScrollArea,
    QPageSticky,
    QInput,
    QForm




  },
  directives: {
    ClosePopup
    
  },
  plugins: {
  }, extras: [
    'Fontawesome'
  ]
 })