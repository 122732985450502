import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import store from "./store/store";
import routes from "./routes";
import "./quasar";

import VueSvgInlinePlugin from "vue-svg-inline-plugin";

import "vue-svg-inline-plugin/src/polyfills";

import VueGTag from "vue-gtag";

Vue.use(VueRouter);
Vue.use(VueSvgInlinePlugin);

const router = new VueRouter({
  routes,
});

Vue.use(
  VueGTag,
  {
    config: { id: "G-2411P29FX0" },
  },
  router
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
